export const headerLinks = [
  {
    title: "ProjectsV2",
    link: "/projects",
  },
  {
    title: "ProjectsV1",
    link: "https://v1.dfistarter.io/#/projects",
    type: 'href'
  },
  {
    title: "Staking",
    link: "/staking",
  },
  {
    title: "Tier",
    link: "/tier",
  },
  {
    title: "Application",
    link: "https://forms.gle/8evjBVBonAiJUzRd8",
    type: 'href'
  },
];
