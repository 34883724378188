import React from "react";
import * as ReactDOMClient from "react-dom/client";
import "SDA/css/reset.css";
import "../src/untils/rem";
import App from "./App";
const root = ReactDOMClient.createRoot(document.getElementById("root"));
root.render(
// <React.StrictMode>
React.createElement(App, null), {
/* </React.StrictMode> */
});
