let en = {
  Collect: "Collect Wallet",
  text: "By connecting a wallet, you agree to DfiStarter Terms of Service and acknowledge that you have read and understand the DfiStarter protocol disclaimer",
  Initializing: "Initializing...",
  error: "Error connecting...",
  Install: "Install Metamask",
  MetaMask: "MetaMask",
  WalletConnect: "WalletConnect",
};

export { en };
