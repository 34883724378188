import ERC20Token from "./abis/ERC20-ABI.json";
import contracts from "web3-eth-contract";
import dfiJson from "./abis/dfi.json";
import dfaJson from "./abis/dfa.json";
import Web3 from "web3";
export const address = {
  // token: "0x239EC95667e37929D33066a8Df8ddC9444DbCBca", //DFI
  // DFAToken: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6", //DFA

  token: "0x2b1faf93823ec9b4bb96a329b84b88d8e57791c2", //DFI test
  DFAToken: "0xc801ABcb6227F37aC82305a1126413f3c239E76c", //DFA test
  // token: "0xa9f1F90d32Ee17a1174Ee6F8f6F7bBB406fC90f9", //DFI test
  // DFAToken: "0xE019F3A922bE39C8d66Dee136485ce16BA2f444f", //DFA test

  MintNFTAddress: "0xb47A058a9c6dc90ddF19337c73Ab2FFa1151b427",
  NFTAddress: "0xd244bCc82ad33a347A9f8463Eb374eE6449d4496",
  faucetAdress: "0xB47B35D405025acE51d6b4e8943214542d744Fa3",
  // dfi: "0x5f1AE5Dd629A9b5453F587F46cC98B32b53043e4",
  // dfa: "0xc5D05993A969C91251828827270824F98Ee6360F",
  dfi: "0x652149C0Ec2E150A8dBf8F36f09843cb82a6d87A",

  dfa: "0x339865870Ed388f3cf4e846503656f2A4B5Cc03B",
};

export const initContracts = () => {
  const web3 = new Web3(
    Web3.givenProvider || "https://data-seed-prebsc-1-s3.binance.org:8545/"
  );
  contracts.setProvider(window.ethereum);
  return {
    // ERC20Contract: new contracts(ERC20Token, address.token),
    // ERC20DFAContract: new contracts(ERC20Token, address.DFAToken),
    ERC20Contract: new contracts(dfiJson, address.dfi),

    ERC20DFAContract: new contracts(dfaJson, address.dfa),
  };
};
