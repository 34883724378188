import React, { memo, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { headerLinks } from "SDS/services/local-data";
import style from "./index.module.scss";
import pic_logo_white from "SDA/home/whiteTheme/pic_Logo.png";
import pic_logo_black from "SDA/home/blackTheme/pic_Logo.png";
import sun from "SDA/nav/sun.png";
import moon from "SDA/nav/moon.png";
import { en } from "SDS/wallet/wallet";
import metamaskPng from "SDA/login/metamask.png";
import WalletConnectPng from "SDA/login/WalletConnect.png";
import walletConnect from "SDS/walletConnect/walletConnect";
import contracts from "web3-eth-contract";
import useBaseState from "SDS/store/modules/config";
const AppHeader = memo(() => {
    const [light, setlight] = useState(true);
    const storage = window.localStorage;
    storage.setItem("theme", light);
    const changeTheme = () => {
        setlight(!light);
        storage.setItem("theme", light);
    };
    //
    const [loginMetamask, setLoginMetamask] = useState(false);
    //metamask安装是否显示
    const [metamaskInstallHide, setMetamaskInstallHide] = useState(true);
    //metamask登录是否显示
    const [metamaskLoginHide, setMetaMaskLoginHide] = useState(false);
    //登录失败显示
    const [metaMaskErrorHide, setMetamaskErrorHide] = useState(true);
    //正在登录显示
    const [metaMaskInstallingHide, setMetamaskInstallingHide] = useState(true);
    //
    const [metaMaskAccount, setMetaMaskAccount] = useState();
    //显示缩写的账户
    const [showAccount, setShowAccount] = useState("");
    //是否显示登录界面
    const [showLogin, setShowLogin] = useState(false);
    //   监听关闭遮罩
    useEffect(() => {
        window.addEventListener("click", (event) => {
            if (event.target.id == "cancelInterface") {
                setShowLogin(true);
            }
            else {
                setShowLogin(false);
            }
        });
        return window.removeEventListener("click", (e) => { });
    }, []);
    //   登录，显示登录界面
    const loginClick = async () => {
        if (storage.getItem("login")) {
            setShowLogin(false);
        }
        else {
            setShowLogin(!showLogin);
            if (typeof window.ethereum !== "undefined") {
                setMetamaskInstallHide(true);
                setMetaMaskLoginHide(false);
                setMetamaskErrorHide(true);
            }
            else {
                setMetamaskInstallHide(false);
                setMetaMaskLoginHide(true);
                setMetamaskErrorHide(true);
            }
        }
    };
    contracts.setProvider(window.ethereum);
    // 获取平衡
    const { baseState, getDfi, getDfa } = useBaseState();
    const [DFIBalance, setDFIBalance] = useState(baseState.dfiBalanceOf);
    const [DFABalance, setDFABalance] = useState(baseState.dfaBalanceOf);
    useEffect(() => {
        if (storage.getItem("login")) {
            (async function () {
                const accounts = await ethereum.request({
                    method: "eth_requestAccounts",
                });
                const account = accounts[0];
                storage.setItem("account", account);
                let show = account.slice(0, 3) + "..." + account.slice(-3);
                setShowAccount(show);
                setLoginMetamask(true);
                try {
                    await getDfi(account);
                    await getDfa(account);
                    setDFIBalance(baseState.dfiBalanceOf);
                    setDFABalance(baseState.dfaBalanceOf);
                }
                catch (error) { }
            })();
        }
    });
    // 判断是否安装了metamask
    useEffect(() => {
        if (typeof window.ethereum !== "undefined") {
            setMetamaskInstallHide(true);
        }
        else {
            setMetamaskInstallHide(false);
            setMetaMaskLoginHide(true);
        }
    });
    //   metamask登录
    const metamaskBtn = async () => {
        setMetamaskInstallingHide(false);
        setMetaMaskLoginHide(true);
        try {
            let res = await ethereum.request({ method: "eth_requestAccounts" });
            let account = res[0];
            let show = account.slice(0, 3) + "..." + account.slice(-3);
            //   0xc3...cace
            setShowAccount(show);
            setMetaMaskAccount(account);
            setLoginMetamask(true);
            setMetamaskInstallingHide(true);
            setShowLogin(false);
            storage.setItem("login", true);
        }
        catch (error) {
            setMetamaskErrorHide(false);
            setMetamaskInstallingHide(true);
        }
    };
    // 账户切换，判断是否存在account
    useEffect(() => {
        (async function () {
            ethereum.on("accountsChanged", function (accounts) {
                if (accounts.length == 1) {
                    let account = accounts[0];
                    storage.setItem("account", account);
                    let show = account.slice(0, 3) + "..." + account.slice(-3);
                    setShowAccount(show);
                }
                else {
                    storage.removeItem("login");
                    setLoginMetamask(false);
                    setMetaMaskLoginHide(false);
                }
            });
        })();
    }, []);
    //   钱包链接
    const walletConnectBtn = async () => {
        let connector = await walletConnect.connector();
        if (!connector.connected) {
            await connector.createSession();
        }
    };
    // 导航栏
    const showItem = (item, index) => {
        if (item.type === 'href') {
            return (React.createElement("a", { className: `${light ? style.a_white : style.a_black}`, target: "_blank", href: item.link }, item.title));
        }
        else {
            return (React.createElement(NavLink
            // activeClassName={style.active}
            , { 
                // activeClassName={style.active}
                className: ({ isActive }) => isActive ? style.active : light ? style.a_white : style.a_black, to: item.link },
                React.createElement("div", null, item.title)));
        }
    };
    return (React.createElement("div", { className: `${style.header} ${light ? style.header_white : style.header_black}` },
        React.createElement("div", { className: style.content },
            React.createElement("div", { className: style.navbar },
                React.createElement("div", { className: style.navbar_brand },
                    React.createElement("img", { className: style.navbar_brand_logo, src: light ? pic_logo_white : pic_logo_black, alt: "" })),
                React.createElement("div", { className: style.sealNavbar },
                    React.createElement("div", { className: style.switchMenu }, headerLinks.map((item, index) => {
                        return (React.createElement("div", { key: item.title, className: style.link }, showItem(item, index)));
                    })),
                    React.createElement("div", { className: style.info },
                        React.createElement("div", { className: `${loginMetamask ? style.balance_show : style.balance_hidden}` },
                            React.createElement("div", { className: `${style.DFI} ${style.bal_normal}` },
                                React.createElement("div", { className: `${light ? style.dif_name_white : style.dif_name_black}` }, "DFI"),
                                React.createElement("div", { className: style.balance_number }, DFIBalance)),
                            React.createElement("div", { className: `${style.DFA} ${style.bal_normal}` },
                                React.createElement("div", { className: `${light ? style.dif_name_white : style.dif_name_black}` },
                                    "DFA",
                                    " "),
                                React.createElement("div", { className: style.balance_number }, DFABalance))),
                        React.createElement("div", { className: style.theme, onClick: (e) => changeTheme() },
                            React.createElement("img", { id: "theme", src: light ? moon : sun, alt: "" })),
                        React.createElement("div", { className: `${style.address} ${light ? style.address_white : style.address_black}` },
                            React.createElement("div", { id: "cancelInterface", onClick: (e) => loginClick(), className: loginMetamask ? style.wallet_hidden : style.wallet_show }, "Connect Wallet"),
                            React.createElement("div", { className: loginMetamask
                                    ? style.walletAccount_show
                                    : style.walletAccount_hidden }, showAccount))),
                    React.createElement("div", { className: showLogin ? style.login_show : style.login_hidden },
                        React.createElement("div", { className: style.connect_mask }),
                        React.createElement("div", { id: "cancelInterface", className: style.popup },
                            React.createElement("div", { id: "cancelInterface", className: style.popup_title }, en.Collect),
                            React.createElement("div", { id: "cancelInterface", className: style.popup_text }, en.text),
                            React.createElement("div", { id: "cancelInterface", className: style.popup_btn_wrap },
                                React.createElement("div", { className: `${style.metaMask} ${metaMaskInstallingHide
                                        ? style.metamask_installing_hiddem
                                        : style.metamask_installing_show}` },
                                    React.createElement("div", { id: "cancelInterface", className: style.metaMask_box },
                                        React.createElement("div", null, en.Initializing))),
                                React.createElement("div", { id: "cancelInterface", className: `${style.metaMask} ${metaMaskErrorHide
                                        ? style.error_connecting_hidden
                                        : style.error_connecting_show}` },
                                    React.createElement("div", { id: "cancelInterface", className: style.metaMask_box },
                                        React.createElement("div", null, en.error))),
                                React.createElement("div", { id: "cancelInterface", className: `${style.metaMask} ${metamaskInstallHide
                                        ? style.install_metamask_hidden
                                        : style.install_metamask_show}` },
                                    React.createElement("div", { id: "cancelInterface", className: style.metaMask_box },
                                        React.createElement("a", { target: "_blank", href: "https://metamask.io/" }, "Install Metamask"),
                                        React.createElement("div", { className: "img-box" },
                                            React.createElement("img", { src: metamaskPng, alt: "" })))),
                                React.createElement("div", { id: "cancelInterface", onClick: (e) => metamaskBtn(), className: `${style.metaMask} ${metamaskLoginHide
                                        ? style.metamask_connect_hidden
                                        : style.metamask_connect_show}` },
                                    React.createElement("div", { id: "cancelInterface", className: style.metaMask_box },
                                        React.createElement("img", { src: metamaskPng, alt: "" }),
                                        React.createElement("div", null, en.MetaMask))),
                                React.createElement("div", { id: "cancelInterface", className: style.metaMask },
                                    React.createElement("div", { id: "cancelInterface", onClick: (e) => walletConnectBtn(), className: style.metaMask_box },
                                        React.createElement("img", { src: WalletConnectPng, alt: "" }),
                                        React.createElement("div", null, en.WalletConnect)))))))))));
});
export default AppHeader;
