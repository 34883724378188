import { useState } from "react";
import Decimal from "decimal.js";
Decimal.set({ toExpPos: 256, precision: 100 });
import { initContracts } from "../../js/contract";
import DfipoolABI from "../../js/abis/dfi-pool-time.abi.json";
import ERC20Token from "../../js/abis/ERC20-ABI.json";
import DfipoolABILimit from "../../js/abis/dfi-pool.abi.json";
import PoolAbi from "../../js/abis/Pool.abi.json";
import IdoAbi from "../../js/abis/ido.json";
import contracts from "web3-eth-contract";
import TDFAABI from "../../js/abis/TDFANFT.json";
import idoBanner from "../../../assets/projectDetail/offerbanner.jpg";
// import twoMonket from 'SDA/testLogo/Monkey.jpeg'
export default function useBaseState() {
    const [baseState, setBaseState] = useState({
        dfiBalanceOf: 0,
        dfaBalanceOf: 0,
        idoList: [
            {
                show: {
                    idoName: "dfa",
                    idoLogoBlack: "/home/tmon-logo",
                    idoLogoWhite: "/home/tmon-logo",
                    idoNme: "dfa",
                    idoType: "Public",
                    ratio: "1 dfa = 1 dfi",
                    cap: 50,
                    // progressBar: "",
                    progress: "",
                    btnContent: "Enter",
                    investedName: "BUSD",
                    progressData: "",
                    combustionToken: "DFA",
                    Limit: "min invest is 1 BUSD, max invest is 10 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://testnet.bscscan.com/address/", //test
                    // linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/TwoMonkeyJB",
                    webSite: "https://www.twomonkeyjuicebar.com/",
                    Telegram: "https://t.me/TwoMonkeyJB",
                    Medium: "https://medium.com/@twomonkeyjuicebar",
                    tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    tokenName: "TMON",
                    tokenCap: "10 TMON",
                    tokenMin: "1 TMON",
                    tokenTotal: "100",
                    Description: "This project is for building a three pronged ecosystem consisting of p2e juice games, nft marketplace and utility, as well as first mover virtual franchise metaverse. They also building a DAPP to aggregate the whole ecosystem together (screenshots in the whitepaper). They are backed by strong backers including Huawei and are in the midst of securing CEX specifically Gate. ",
                },
                isProgress: true,
                isExist: true,
                isTime: true,
                isWhitelist: false,
                isJion: false,
                isEnter: true,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: IdoAbi,
                // ABI: PoolAbi,
                ERC20ABI: ERC20Token,
                ERC721ABI: TDFAABI,
                // idoAddress: "0xFAB07742D7Cf5709f44383BD4be68a5461D05701",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                // idoAddress: "0xbe961277CFd39176048780338Bb855513e2Df060", //testnp
                // idoAddress: "0x3Fe6F9e1a883558c64C57AcFC3886EEDaEBEac84", //testnp
                idoAddress: "0xe004C1ed9a77102420379040da2561d0fA9Af812",
                jionAddress: "0x652149C0Ec2E150A8dBf8F36f09843cb82a6d87A",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                nftAddress: "0x3Aefa16928EbfA4E406eABB731C246a48Ac206E6",
                // jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69", //test
                // combustionAddress: "0x339865870Ed388f3cf4e846503656f2A4B5Cc03B", //test dfi
                isQuota: true,
                quotaMin: 1,
                quotaMax: 10,
                fundraising: 1666667,
                isApproveTwo: true,
            },
        ],
        idoLists: [],
        // closed pool
        idoListClosed: [
            {
                show: {
                    idoName: "Meme cake Public Pool",
                    idoLogoBlack: "/home/mem-logo",
                    idoLogoWhite: "/home/mem-logo",
                    idoNme: "Port3",
                    idoType: "Whitelist",
                    ratio: "1 Port3 = 0.0000 2227ETH",
                    cap: 1400000,
                    banner: idoBanner,
                    progressBar: "",
                    progress: "",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    // Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    // limitNote: "Each BUSD invested requires One DFA",
                    // linkUrl: "https://testnet.bscscan.com/address/",//test
                    // linkUrl: "https://bscscan.com/address/",
                    LimitMin: 50,
                    LimitMax: 300,
                    limitNote: "BUSD : DFA ",
                    limitNoteRatio: "1 : 2",
                    linkUrl: "https://bscscan.com/address/",
                    joinTokenName: "BUSD",
                    combustionToken: "DFA",
                }
            },
            {
                show: {
                    idoName: "Meme cake Public Pool",
                    idoLogoBlack: "/home/mem-logo",
                    idoLogoWhite: "/home/mem-logo",
                    idoNme: "Meme cake",
                    idoType: "DFA",
                    ratio: "1 MCAKE = 0.004 BUSD",
                    cap: 7500000,
                    progressBar: "",
                    progress: "",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    // Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    // limitNote: "Each BUSD invested requires One DFA",
                    // linkUrl: "https://testnet.bscscan.com/address/",//test
                    // linkUrl: "https://bscscan.com/address/",
                    LimitMin: 50,
                    LimitMax: 300,
                    limitNote: "BUSD : DFA ",
                    limitNoteRatio: "1 : 2",
                    linkUrl: "https://bscscan.com/address/",
                    joinTokenName: "BUSD",
                    combustionToken: "DFA",
                },
                details: {
                    Twitter: "https://twitter.com/realmemecake",
                    webSite: "http://memecake.io/",
                    Telegram: " https://t.me/meme_cake ",
                    Medium: " ",
                    tokenAddress: "0x79D17bf182FfaeF84d877CD27b706550b21CF6E0",
                    tokenName: "MCAKE",
                    tokenCap: "250000 MCAKE",
                    tokenMin: "25000 MCAKE",
                    tokenTotal: "100000000000",
                    Description: "Meme Cake is the worlds first Multi-chain social NFT marketplace built on Dfinity and Solana! A platform where projects can reach out to verified community members for support.",
                },
                isProgress: false,
                isTime: false,
                isWhitelist: false,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: PoolAbi,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x45B843C33F92C3EbD625F9A4c0aDe357f00f8fB6",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0xd83D48e89b895b48b34EA14fDFF5359E8836Fe61",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 30000,
            },
            {
                show: {
                    idoName: "Two Monkey Juice Bar Public Pool",
                    idoLogoBlack: "/home/tmon-logo",
                    idoLogoWhite: "/home/tmon-logo",
                    idoNme: "Two Monkey Juice Bar",
                    idoType: "Public",
                    ratio: "1 TMON = 0.03 BUSD",
                    cap: 50000,
                    // progressBar: "",
                    progress: "",
                    btnContent: "Enter",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://testnet.bscscan.com/address/", //test
                    // linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/TwoMonkeyJB",
                    webSite: "https://www.twomonkeyjuicebar.com/",
                    Telegram: "https://t.me/TwoMonkeyJB",
                    Medium: "https://medium.com/@twomonkeyjuicebar",
                    tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    tokenName: "TMON",
                    tokenCap: "33333 TMON",
                    tokenMin: "3333 TMON",
                    tokenTotal: "1000000000",
                    Description: "This project is for building a three pronged ecosystem consisting of p2e juice games, nft marketplace and utility, as well as first mover virtual franchise metaverse. They also building a DAPP to aggregate the whole ecosystem together (screenshots in the whitepaper). They are backed by strong backers including Huawei and are in the midst of securing CEX specifically Gate. ",
                },
                isProgress: true,
                isExist: true,
                isTime: true,
                isWhitelist: false,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: PoolAbi,
                ERC20ABI: ERC20Token,
                // idoAddress: "0xFAB07742D7Cf5709f44383BD4be68a5461D05701",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0xbe961277CFd39176048780338Bb855513e2Df060",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 1666667,
                isApproveTwo: true,
            },
            {
                show: {
                    idoName: "Owl Analytics Public Pool",
                    idoLogoBlack: "/home/owl-logo",
                    idoLogoWhite: "/home/owl-logo",
                    idoNme: "Owl Analytics",
                    idoType: "Public",
                    ratio: "1 OWLA = 0.05 BUSD",
                    cap: 50000,
                    progressBar: "",
                    progress: "",
                    btnContent: "Enter",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://testnet.bscscan.com/address/", //test
                    // linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/TheOwlAnalytics",
                    webSite: "https://owlanalytics.org",
                    Telegram: "https://t.me/Owl_analytics",
                    Medium: " ",
                    tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    tokenName: "OWLA",
                    tokenCap: "20000 OWLA",
                    tokenMin: "2000 OWLA",
                    tokenTotal: "99000000",
                    Description: "This project is for analyzing and clarifying digital currencies to help users get more information and $OWLA is designed as a decentralized protocol that intends to establish liquidity for a specific type of digital assets called non-fungible tokens ( NFTs ) .",
                },
                isProgress: false,
                isTime: true,
                isWhitelist: false,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0xC4d88BCD4D49DFEcAe7C2eF9b1b357E8a2F046C9",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0x4A459511fb6Bfd5C2F442Bee6C4F7FaFf7E859A6",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 1000000,
            },
            {
                show: {
                    idoName: "Owl Analytics Public Pool",
                    idoLogoBlack: "/home/owl-logo",
                    idoLogoWhite: "/home/owl-logo",
                    idoNme: "Owl Analytics",
                    idoType: "Whitelist",
                    ratio: "1 OWLA = 0.05 BUSD",
                    cap: 50000,
                    progressBar: "",
                    progress: "",
                    btnContent: "Enter",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 200 BUSD, max invest is 200 BUSD",
                    // limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://testnet.bscscan.com/address/", //test
                    // linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/TheOwlAnalytics",
                    webSite: "https://owlanalytics.org",
                    Telegram: "https://t.me/Owl_analytics",
                    Medium: " ",
                    tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    tokenName: "OWLA",
                    tokenCap: "4000 OWLA",
                    tokenMin: "4000 OWLA",
                    tokenTotal: "99000000",
                    Description: "This project is for analyzing and clarifying digital currencies to help users get more information and $OWLA is designed as a decentralized protocol that intends to establish liquidity for a specific type of digital assets called non-fungible tokens ( NFTs ) .",
                },
                isProgress: false,
                isTime: true,
                isWhitelist: true,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x120e503675cbdAB98Bf62e4d37c4114d4eC972bB",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0xE4672C46b600ddA1202876aA32038899C39CFf13",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 200,
                quotaMax: 200,
                fundraising: 1000000,
            },
            // {
            //   show: {
            //     idoName: "Meme cake Public Pool",
            //     idoLogoBlack: "/home/mem-logo",
            //     idoLogoWhite: "/home/mem-logo",
            //     idoNme: "Meme cake",
            //     idoType: "DFA",
            //     ratio: "1 MCAKE = 0.004 BUSD",
            //     cap: 7500000,
            //     progressBar: "17.48%",
            //     progress: "17.48%",
            //     btnContent: "Closed",
            //     investedName: "BUSD",//invested
            //     progressData: "",
            //     Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
            //     limitNote: "Each BUSD invested requires One DFA",
            //     linkUrl: "https://testnet.bscscan.com/address/",//test
            //     // linkUrl: "https://bscscan.com/address/",
            //   },
            //   details: {
            //     Twitter: "https://twitter.com/realmemecake",
            //     webSite: "http://memecake.io/",
            //     Telegram: " https://t.me/meme_cake ",
            //     Medium: " ",
            //     tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            //     tokenName: "MCAKE",
            //     tokenCap: "250000 MCAKE",
            //     tokenMin: "25000 MCAKE",
            //     tokenTotal: "100000000000",
            //     Description: "Meme Cake is the worlds first Multi-chain social NFT marketplace built on Dfinity and Solana! A platform where projects can reach out to verified community members for support.",
            //   },
            //   isProgress: true,
            //   isWhitelist: false,
            //   isJion: false,
            //   isEnter: true,
            //   isClaim: false,
            //   startTime: '',
            //   endTime: '',
            //   idoId: '',
            //   tokenAccurate: 2,
            //   ABI: DfipoolABILimit,
            //   ERC20ABI: ERC20Token,
            //   // idoAddress: "0xb5e929a32D25198D0a86FF7E20C6Dc2FE3371c8B",
            //   // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            //   // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
            //   idoAddress: "0x6cFb7cf1b3Ec9F322D383b724171f2f4821EFa12",//test
            //   jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",//test
            //   combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",//test
            //   isQuota: true,  //Is there a quota limit
            //   quotaMin: 100,  // Minimum investment
            //   quotaMax: 1000,// Maximum investment
            //   fundraising: 30000
            // },
            // {
            //   show: {
            //     idoName: "Meme cake Public Pool",
            //     idoLogoBlack: "/home/mem-logo",
            //     idoLogoWhite: "/home/mem-logo",
            //     idoNme: "Meme cake",
            //     idoType: "Whitelist",
            //     ratio: "1 MCAKE = 0.004 BUSD",
            //     cap: 7500000,
            //     progressBar: "3.33%",
            //     progress: "3.33%",
            //     btnContent: "Closed",
            //     investedName: "BUSD",//invested
            //     progressData: "",
            //     Limit: "min invest is 200 BUSD, max invest is 200 BUSD",
            //     limitNote: "Each BUSD invested requires One DFA",
            //     linkUrl: "https://testnet.bscscan.com/address/",//test
            //     // linkUrl: "https://bscscan.com/address/",
            //   },
            //   details: {
            //     Twitter: "https://twitter.com/realmemecake",
            //     webSite: "http://memecake.io/",
            //     Telegram: " https://t.me/meme_cake ",
            //     Medium: " ",
            //     tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            //     tokenName: "MCAKE",
            //     tokenCap: "250000 MCAKE",
            //     tokenMin: "25000 MCAKE",
            //     tokenTotal: "100000000000",
            //     Description: "Meme Cake is the worlds first Multi-chain social NFT marketplace built on Dfinity and Solana! A platform where projects can reach out to verified community members for support.",
            //   },
            //   isProgress: true,
            //   isWhitelist: true,
            //   isJion: false,
            //   isEnter: true,
            //   isClaim: false,
            //   startTime: '',
            //   endTime: '',
            //   idoId: '',
            //   tokenAccurate: 2,
            //   ABI: DfipoolABILimit,
            //   ERC20ABI: ERC20Token,
            //   // idoAddress: "0x189955769Dd14980be5e75E0f1dD0b51C488535D",
            //   // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
            //   // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
            //   idoAddress: "0xAa7b2a02fD19713Aa85d40B73b4a54841fe3E652",//test
            //   jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",//test
            //   combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",//test
            //   isQuota: true,  //Is there a quota limit
            //   quotaMin: 200,  // Minimum investment
            //   quotaMax: 200,// Maximum investment
            //   fundraising: 30000
            // },
            {
                show: {
                    idoName: "Cheesus Defi Public Pool",
                    idoLogoBlack: "/home/cheesus",
                    idoLogoWhite: "/home/cheesus",
                    idoNme: "Cheesus Defi",
                    idoType: "Public",
                    ratio: "1 CHEESUS = 0.038 BUSD",
                    cap: 1842105,
                    progressBar: "18.12%",
                    progress: "18.12%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    // linkUrl: "https://testnet.bscscan.com/address/",//test
                    linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: " https://twitter.com/CheesusDeFi",
                    webSite: "https://cheesus.ai/en/main",
                    Telegram: " Telegram: t.me/Cheesus_Announcements ",
                    Medium: " ",
                    tokenAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                    tokenName: "CHEESUS",
                    tokenCap: "26315 CHEESUS",
                    tokenMin: "2631 CHEESUS",
                    tokenTotal: "130000000",
                    Description: "ON CHAIN trading and data analyst tool for tracking NFT & FT trading at any Metaverses and DEXes on Solana, EVM-chains and others.",
                },
                isProgress: true,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0xccBCEF1bfaB1808c1c895F37117f8dfd8ad76D3e",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0x958F73fd1C888aF40Fae0F1B94122C22503e3bCd",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 70000,
            },
            {
                show: {
                    idoName: "Paycer Protocol Public Pool",
                    idoLogoBlack: "/home/pcy_logo",
                    idoLogoWhite: "/home/pcy_logo",
                    idoNme: "Paycer Protocol",
                    idoType: "Public",
                    ratio: "1 PCR = 0.055 BUSD",
                    cap: 909090,
                    progressBar: "78.13%",
                    progress: "78.13%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 50 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    // linkUrl: "https://bscscan.com/address/",
                    linkUrl: "https://www.bscscan.com/address/",
                },
                details: {
                    Twitter: " https://twitter.com/paycerprotocol",
                    webSite: "https://www.paycer.io/",
                    Telegram: " https://t.me/paycerprotocol",
                    Medium: " https://paycerprotocol.medium.com",
                    tokenAddress: "0xa6083abe845fbB8649d98B8586cBF50b7f233612",
                    tokenName: "PCR",
                    tokenCap: "18181.8 PCR",
                    tokenMin: "909 PCR",
                    tokenTotal: "750000000",
                    Description: "The Paycer team is developing a bridge protocol that aggregates DeFi and crypto services cross-chain and combines them with TradFi to create new user-friendly financial products and services available on an easy to use platform.",
                },
                isProgress: true,
                isJion: false,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x67661d300cD19411c63DAa29ADc208FF80cA9AE4",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0x8b2cbD6B23BFFef718D7a5B2e1cB11f603a303db",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 50,
                quotaMax: 1000,
                fundraising: 50000,
            },
            {
                show: {
                    idoName: "MetaPad Public Pool",
                    idoLogoBlack: "/home/meta_logo",
                    idoLogoWhite: "/home/meta_logo",
                    idoNme: "MetaPad",
                    idoType: "Public",
                    ratio: "1 MPD = 0.065 BUSD",
                    cap: 1230000,
                    progressBar: "100%",
                    progress: "100%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/meta_ecosystem",
                    webSite: "https://metaversepad.app/",
                    Telegram: "https://t.me/MetaPad_announcement",
                    Medium: "https://medium.com/@metaverse.ecosystem",
                    tokenAddress: "0xD88C6Ec2D3fBD90892c6749CF83De6ad10c30B4b",
                    tokenName: "MPD",
                    tokenCap: "15384 MPD",
                    tokenMin: "1538.5 MPD",
                    tokenTotal: "100000000",
                    Description: "The MetaPad is a decentralized platform for fundrising and launching game projects on the metaverse ecosystem.",
                },
                isProgress: true,
                isJion: false,
                isEnter: false,
                isClaim: true,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0xFC21eC42a4bD90FE90a6cBE1A70bf2341Be7b58b",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0xC61c90F14c9076c323085f70Cb755F94e44D8a58",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 80000,
            },
            {
                show: {
                    idoName: "Astra Guild Public Pool",
                    idoLogoBlack: "/home/AGV_logo",
                    idoLogoWhite: "/home/AGV_logo",
                    idoNme: "Astra Guild",
                    idoType: "Public",
                    ratio: "1 AGV = 0.20 BUSD",
                    cap: 150000,
                    progressBar: "100%",
                    progress: "100%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: 150000,
                    Limit: "min invest is 100 BUSD, max invest is 1000 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    linkUrl: "https://bscscan.com/address/",
                },
                details: {
                    Twitter: "https://twitter.com/astraguild",
                    webSite: "https://astraguild.io/",
                    Telegram: "https://telegram.me/astraguildannouncements",
                    Medium: "https://astraguildventures.medium.com/",
                    tokenAddress: "0xf4f618eff5ef36cde2fca4fbd86554c62fb1382b",
                    tokenName: "AGV",
                    tokenCap: "5000 AGV",
                    tokenMin: "500 AGV",
                    tokenTotal: "2000000000",
                    Description: "AGV is one of the largest #PlayToEarn guilds. A DAO where people with time and people with funds work together. #Guild #Axie #NFT",
                },
                isProgress: true,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x9F0AbA7E1d033894B0eDF3636072520656355e00",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0x2B833accf0Cb8EAFab569929dA8e085b12C4e396",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 1000,
                fundraising: 100000,
            },
            {
                show: {
                    idoName: "CryptoBattles Public Pool",
                    idoLogoBlack: "/home/CryptoBattles",
                    idoLogoWhite: "/home/CryptoBattles",
                    idoNme: "CryptoBattles",
                    idoType: "Public",
                    ratio: "1 CBT = 0.10 BUSD",
                    cap: 500000,
                    progressBar: "100%",
                    progress: "100%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 50 BUSD, max invest is 600 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                },
                details: {
                    Twitter: "https://twitter.com/CryptoWarsOU",
                    webSite: "https://cryptobattles.games/",
                    Telegram: "https://t.me/CWGroup",
                    // Medium: "https://medium.com/@metaverse.ecosystem",
                    tokenAddress: "0xeA8f52e3BdD7446aB33f1088ba7a04BE0DC1118C",
                    tokenName: "CBT",
                    tokenCap: "6000 CBT",
                    tokenMin: "500 CBT",
                    tokenTotal: "100000000",
                    Description: "CryptoBattles is a revolutionary a web based blockchain PVP game launched on the Binance Smart Chain.",
                },
                isProgress: true,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x547eD640208B5136381bE005ECcff3de1a8E6C6E",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0x97a20baA445CF0fA783ef84146BD62F7e68234e6",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 50,
                quotaMax: 600,
                fundraising: 50000,
            },
            {
                show: {
                    idoName: "ACY Finance Public Pool",
                    idoLogoBlack: "/home/acy_logo",
                    idoLogoWhite: "/home/acy_logo",
                    idoNme: "ACY Finance",
                    idoType: "Public",
                    ratio: "1 ACY =  0.2 BUSD",
                    cap: 500000,
                    progressBar: "100%",
                    progress: "100%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 100 BUSD, max invest is 500 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                },
                details: {
                    Twitter: "https://twitter.com/ACYFinance",
                    webSite: "https://acy.finance/",
                    Telegram: "https://t.me/acyfinance",
                    Medium: "https://medium.com/acy-finance",
                    tokenAddress: "0xe6e5d59862f5d03b68e4c4d4f7f6b5246789e58b",
                    tokenName: "ACY",
                    tokenCap: "2500 ",
                    tokenMin: "500 ",
                    tokenTotal: "100000000",
                    Description: "ACY Finance is an anti-robot DEX. ACY invents Flash Arbitrage, a protocol-level implementation that executes to reduce the arbitrage activities of miners, arbitragers.",
                },
                isProgress: true,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                // idoAddress: "0x0632418f46c0E5E415EC10726e71C6434A0de47A",
                // jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                // combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                idoAddress: "0xb128688510bC20e5575Ff969419C830265fB4F75",
                jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 100,
                quotaMax: 500,
                fundraising: 100000,
            },
            {
                show: {
                    idoName: "CCOMetaverse Public Pool",
                    idoLogoBlack: "/home/ccom_logo",
                    idoLogoWhite: "/home/ccom_logo",
                    idoNme: "CCOMetaverse",
                    idoType: "Public",
                    ratio: "1 CCOM =  0.019 BUSD",
                    cap: 2631578,
                    progressBar: "96.87%",
                    progress: "96.87%",
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 50 BUSD, max invest is 300 BUSD",
                    limitNote: "Each BUSD invested requires Two DFA",
                },
                details: {
                    Twitter: "https://twitter.com/ccometaverse",
                    webSite: "https://ccometaverse.tech/",
                    Telegram: "https://t.me/ccometaverse",
                    Medium: "https://medium.com/@ccometaverse",
                    tokenAddress: "0x81de885e048c7b236482c0a58d4eb8675b7955a1",
                    tokenName: "CCOM",
                    tokenCap: "15789.5 ",
                    tokenMin: "2631.5",
                    tokenTotal: "100000000",
                    Description: "CCOM is an open world metaverse game, players can interact with each other and trade, the game content revolves around the story of each character to do missions, and players can participate in personal games.",
                },
                isProgress: true,
                isEnter: false,
                isClaim: false,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABILimit,
                ERC20ABI: ERC20Token,
                idoAddress: "0x0632418f46c0E5E415EC10726e71C6434A0de47A",
                jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                // idoAddress: "0x56F0aA2D68cfBa283fC38Ce2Febb402EB2170c4F",
                // jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                // combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: true,
                quotaMin: 50,
                quotaMax: 300,
                fundraising: 50000,
            },
            {
                show: {
                    idoName: "NFTC Public Pool",
                    idoLogoBlack: "/home/nftc-logo-black",
                    idoLogoWhite: "/home/nftc-logo-white",
                    idoNme: "NFTCircle",
                    idoType: "Public",
                    ratio: "1NFTC = 0.02 BUSD",
                    cap: 1500000,
                    btnContent: "Closed",
                    investedName: "BUSD",
                    progressData: "",
                    Limit: "min invest is 50 BUSD",
                    limitNote: "Each BUSD invested requires One DFA",
                    progress: "100%",
                    progressBar: "252.48%", //Show percentage
                },
                details: {
                    Twitter: "https://twitter.com/nft_circle",
                    webSite: "https://www.nftcircle.io/",
                    Telegram: "https://t.me/joinchat/IxnVUuZWp3BiY2U1",
                    Medium: "https://medium.com/@Nftcircle.io",
                    tokenAddress: "0x2efcdd1383eae3af14d785dcc65d6b865b562312",
                    tokenName: "NFTC",
                    tokenCap: "1500000 ",
                    tokenMin: "125000 ",
                    tokenTotal: "100050000",
                    Description: "NFTCircle.io offers a new generation of NFT through AR by Toppan Tabitori and visual art under ARTMYN for the art ecosystem.",
                },
                isProgress: true,
                isEnter: false,
                isClaim: true,
                startTime: "",
                endTime: "",
                idoId: "",
                tokenAccurate: 2,
                ABI: DfipoolABI,
                ERC20ABI: ERC20Token,
                idoAddress: "0xB519e9Ff0bc8CAC80fF8Dab48d97525C3305Fa8F",
                jionAddress: "0xe9e7cea3dedca5984780bafc599bd69add087d56",
                combustionAddress: "0x7bf6247CA3eA424AB074D051f25F673f0ae2B8a6",
                // idoAddress: "0x45B843C33F92C3EbD625F9A4c0aDe357f00f8fB6",
                // jionAddress: "0x55ef95ADafA9624E7039789F3dcBb83e2F8b3f69",
                // combustionAddress: "0xc801ABcb6227F37aC82305a1126413f3c239E76c",
                isQuota: false, //Is there a quota limit
            },
            {
                show: {
                    idoName: "DFI Public Pool",
                    idoLogoBlack: "/home/projectsLogo",
                    idoLogoWhite: "/home/projectsLogo",
                    idoNme: "DFI",
                    idoType: "NFT",
                    ratio: "1DFI = 0.2 BUSD",
                    cap: "125,000",
                    progress: "99%",
                    progressBar: "99%",
                    btnContent: "Closed",
                },
                details: {
                    Twitter: "https://twitter.com/ACYFinance",
                    webSite: "https://acy.finance/",
                    Telegram: "https://t.me/acyfinance",
                    Medium: "https://medium.com/acy-finance",
                    tokenAddress: "0xe6e5d59862f5d03b68e4c4d4f7f6b5246789e58b",
                    tokenName: "ACY",
                    tokenCap: "2500 ",
                    tokenMin: "500 ",
                    tokenTotal: "100000000",
                    Description: "ACY Finance is an anti-robot DEX. ACY invents Flash Arbitrage, a protocol-level implementation that executes to reduce the arbitrage activities of miners, arbitragers.",
                },
                isProgress: true,
                isEnter: false,
                idoId: "",
                ABI: DfipoolABI,
            },
            {
                show: {
                    idoName: "DFI Public Pool",
                    idoLogoBlack: "/home/projectsLogo",
                    idoLogoWhite: "/home/projectsLogo",
                    idoNme: "DFI",
                    idoType: "Whitelist",
                    ratio: "1DFI = 0.2 BUSD",
                    cap: "625,000",
                    progress: "85.3%",
                    progressBar: "85.3%",
                    btnContent: "Closed",
                },
                details: {
                    Twitter: "https://twitter.com/ACYFinance",
                    webSite: "https://acy.finance/",
                    Telegram: "https://t.me/acyfinance",
                    Medium: "https://medium.com/acy-finance",
                    tokenAddress: "0xe6e5d59862f5d03b68e4c4d4f7f6b5246789e58b",
                    tokenName: "ACY",
                    tokenCap: "2500 ",
                    tokenMin: "500 ",
                    tokenTotal: "100000000",
                    Description: "ACY Finance is an anti-robot DEX. ACY invents Flash Arbitrage, a protocol-level implementation that executes to reduce the arbitrage activities of miners, arbitragers.",
                },
                isProgress: true,
                isEnter: false,
                idoId: "",
                ABI: DfipoolABI,
            },
            {
                show: {
                    idoName: "DFI Public Pool",
                    idoLogoBlack: "/home/projectsLogo",
                    idoLogoWhite: "/home/projectsLogo",
                    idoNme: "DFI",
                    idoType: "Public",
                    ratio: "1DFI = 0.2 BUSD",
                    cap: "100,000",
                    progress: "100%",
                    progressBar: "100%",
                    btnContent: "Closed",
                },
                details: {
                    Twitter: "https://twitter.com/ACYFinance",
                    webSite: "https://acy.finance/",
                    Telegram: "https://t.me/acyfinance",
                    Medium: "https://medium.com/acy-finance",
                    tokenAddress: "0xe6e5d59862f5d03b68e4c4d4f7f6b5246789e58b",
                    tokenName: "ACY",
                    tokenCap: "2500 ",
                    tokenMin: "500 ",
                    tokenTotal: "100000000",
                    Description: "ACY Finance is an anti-robot DEX. ACY invents Flash Arbitrage, a protocol-level implementation that executes to reduce the arbitrage activities of miners, arbitragers.",
                },
                isProgress: true,
                isEnter: false,
                idoId: "",
                ABI: DfipoolABI,
            },
        ],
        userInfo: {},
        newUserInfo: {},
        isPurchaseSuccess: false,
        isClaimSuccess: false,
        dfiNot: false,
        stakeAmount: "",
    });
    async function getDfi(account) {
        let address = account;
        let configContract = await initContracts();
        let balanceOf = await configContract.ERC20Contract.methods
            .balanceOf(address)
            .call();
        balanceOf = new Decimal(balanceOf)
            .div(new Decimal(Math.pow(10, 18)))
            .toFixed(2, Decimal.ROUND_FLOOR)
            .toString();
        baseState.dfiBalanceOf = balanceOf;
        setBaseState(baseState);
    }
    async function getDfa(account) {
        let address = account;
        let configContract = await initContracts();
        let balanceOf = await configContract.ERC20DFAContract.methods
            .balanceOf(address)
            .call();
        balanceOf = new Decimal(balanceOf)
            .div(new Decimal(Math.pow(10, 18)))
            .toFixed(2, Decimal.ROUND_FLOOR)
            .toString();
        baseState.dfaBalanceOf = balanceOf;
        setBaseState(baseState);
    }
    async function getCloseData(idoAddress) {
        let arr = [];
        let arrData = [];
        for (let i = 0; i < baseState.idoList.length; i++) {
            let obj = baseState.idoList[i];
            if (obj.idoAddress === idoAddress) {
                let configContract = new contracts(obj.ABI, obj.idoAddress);
                try {
                    // let res = configContract.methods.getProgress().call();
                    // console.log("progressInfo", res);
                    // .then(() => {
                    let progressLength = new Decimal(obj.progress * 100).toFixed(obj.tokenAccurate, Decimal.ROUND_FLOOR);
                    //Length of progress bar
                    obj.show.progress =
                        Number(progressLength) > 100 ? "100%" : progressLength + "%";
                    //Impression percentages
                    obj.show.progressBar = progressLength + "%";
                    obj.isEnter = false;
                    obj.show.btnContent = "Closed";
                    arr.push(obj);
                    for (let i = 0; i < arr.length; i++) {
                        if (!arr[i].isEnter) {
                            baseState.idoListClosed.push(arr[i]);
                            arrData.push(arr[i]);
                            arr.splice(i--, 1);
                        }
                    }
                    baseState.idoList = arr;
                    setBaseState(baseState);
                }
                catch (error) { }
            }
        }
    }
    //Get pool progress bar information
    async function getProgress() {
        let arr = [];
        for (let i = 0; i < baseState.idoList.length; i++) {
            let obj = baseState.idoList[i];
            if (obj.show.idoNme) {
                // let configContract = new contracts(obj.ABI, obj.idoAddress);
                let configContract = new contracts(obj.ABI, obj.idoAddress);
                try {
                    let progressInfo = await configContract.methods
                        // .getProgress()
                        .totalPurchaseds()
                        .call();
                    obj.idoId = i;
                    obj.progress = new Decimal(progressInfo.progress).div(new Decimal(Math.pow(10, 18)));
                    let progressData = new Decimal(progressInfo.progress).div(new Decimal(Math.pow(10, 18)));
                    let progressLength = new Decimal(obj.progress * 100).toFixed(obj.tokenAccurate, Decimal.ROUND_FLOOR);
                    // Show investment quota
                    if (obj.show.idoNme === "MetaPad") {
                        obj.show.progressData = obj.show.cap;
                    }
                    else {
                        obj.show.progressData =
                            new Decimal(Number(progressData) * obj.show.cap) > obj.show.cap
                                ? obj.show.cap
                                : new Decimal(Number(progressData) * obj.show.cap).toFixed(obj.tokenAccurate, Decimal.ROUND_FLOOR);
                    }
                    //Length of progress bar
                    if (obj.show.idoNme === "MetaPad") {
                        obj.show.progress = "100%";
                    }
                    else {
                        obj.show.progress =
                            Number(progressLength) > 100 ? "100%" : progressLength + "%";
                    }
                    //Impression percentages
                    obj.show.progressBar =
                        obj.show.idoNme === "MetaPad" ? 100 + "%" : progressLength + "%";
                    obj.claimTime = progressInfo.claimTime;
                    obj.endTime = progressInfo.endTime;
                    obj.startTime = progressInfo.startTime;
                    // Total fundraising
                    obj.total = new Decimal(progressInfo.total)
                        .div(new Decimal(Math.pow(10, 18)))
                        .toFixed(obj.tokenAccurate, Decimal.ROUND_FLOOR);
                    obj.isApproveTwo = true;
                    arr.push(obj);
                }
                catch (error) {
                    console.log("error", error);
                }
                // await configContract.methods
                //   // .getProgress()
                //   .totalPurchaseds()
                //   .call()
                //   .then((progressInfo) => {
                //     console.log("progressInfo", progressInfo);
                //     // console.log($progressInfo, 'configContract')
                //     obj.idoId = i;
                //     obj.progress = new Decimal(progressInfo.progress).div(
                //       new Decimal(Math.pow(10, 18))
                //     );
                //     let progressData = new Decimal(progressInfo.progress).div(
                //       new Decimal(Math.pow(10, 18))
                //     );
                //     let progressLength = new Decimal(obj.progress * 100).toFixed(
                //       obj.tokenAccurate,
                //       Decimal.ROUND_FLOOR
                //     );
                //     // Show investment quota
                //     if (obj.show.idoNme === "MetaPad") {
                //       obj.show.progressData = obj.show.cap;
                //     } else {
                //       obj.show.progressData =
                //         new Decimal(Number(progressData) * obj.show.cap) > obj.show.cap
                //           ? obj.show.cap
                //           : new Decimal(Number(progressData) * obj.show.cap).toFixed(
                //               obj.tokenAccurate,
                //               Decimal.ROUND_FLOOR
                //             );
                //     }
                //     //Length of progress bar
                //     if (obj.show.idoNme === "MetaPad") {
                //       obj.show.progress = "100%";
                //     } else {
                //       obj.show.progress =
                //         Number(progressLength) > 100 ? "100%" : progressLength + "%";
                //     }
                //     //Impression percentages
                //     obj.show.progressBar =
                //       obj.show.idoNme === "MetaPad" ? 100 + "%" : progressLength + "%";
                //     obj.claimTime = progressInfo.claimTime;
                //     obj.endTime = progressInfo.endTime;
                //     obj.startTime = progressInfo.startTime;
                //     // Total fundraising
                //     obj.total = new Decimal(progressInfo.total)
                //       .div(new Decimal(Math.pow(10, 18)))
                //       .toFixed(obj.tokenAccurate, Decimal.ROUND_FLOOR);
                //     obj.isApproveTwo = true;
                //     arr.push(obj);
                //   })
                //   .catch(() => {
                //     // arr.push(state.idoList[i])
                //   });
            }
            arr.forEach((item, index) => {
                if (item.isEnter) {
                    // commit("updateProgressInfo", arr);
                    baseState.idoList = arr;
                }
            });
            setBaseState(baseState);
            // commit("updateIdoListClosed", state.idoListClosed);
        }
    }
    async function getNewUserInfo(account, item) {
        let address = account;
        // console.log(payload.ABI, payload.idoAddress)
        let IDOPoolContract = new contracts(item.ABI, item.idoAddress);
        let newUserInfo = await IDOPoolContract.methods
            .getUserInfo(address)
            .call()
            .catch((e) => {
            return {
                invested: 0,
                shares: 0,
                accelerated: false,
                exits: false,
                claimed: false,
                refund: 0,
            };
        });
        return newUserInfo.stakingChecked;
        // console.log(newUserInfo.stakingChecked, 'userInfo')
        // commit('getNewUserInfo', newUserInfo)
    }
    async function getUserInfo(account, item) {
        let address = account;
        let IDOPoolContract = new contracts(item.ABI, item.idoAddress);
        let userInfo = await IDOPoolContract.methods
            // .getUserInfo(address)
            .showUserInfo(address)
            .call()
            .catch((e) => {
            return {
                invested: 0,
                shares: 0,
                accelerated: false,
                exits: false,
                claimed: false,
                refund: 0,
            };
        });
        // console.log(userInfo.stakingChecked, 'userInfo')
        // userInfo.invested = new Decimal(userInfo.invested)
        let res = new Decimal(userInfo[0])
            .div(new Decimal(Math.pow(10, 18)))
            .toString();
        let newUseInfo = { invested: "0", shares: "0" };
        newUseInfo.invested = new Decimal(userInfo.purchaseAmount)
            .div(new Decimal(Math.pow(10, 18)))
            .toString();
        newUseInfo.shares = new Decimal(userInfo.claimAmount)
            .div(new Decimal(Math.pow(10, 18)))
            .toString();
        // userInfo.invested = new Decimal(userInfo.purchaseAmount)
        //   .div(new Decimal(Math.pow(10, 18)))
        //   .toString();
        // userInfo.shares = new Decimal(userInfo.claimAmount)
        //   .div(new Decimal(Math.pow(10, 18)))
        //   .toString();
        setBaseState(baseState);
    }
    async function getStakeAmount(item) {
        let IDOPoolContract = new contracts(item.ABI, item.idoAddress);
        let stakeAmount = await IDOPoolContract.methods
            .stakingAmount()
            .call()
            .catch((e) => {
            return {};
        });
        return new Decimal(stakeAmount)
            .div(new Decimal(Math.pow(10, 18)))
            .toString();
    }
    // async function getUserInfo(account, item) {
    //   let address = account;
    //   let IDOPoolContract = new contracts(payload.ABI, payload.idoAddress);
    //   let userInfo = await IDOPoolContract.methods
    //     .getUserInfo(address)
    //     .call()
    //     .catch((e) => {
    //       return {
    //         invested: 0,
    //         shares: 0,
    //         accelerated: false,
    //         exits: false,
    //         claimed: false,
    //         refund: 0,
    //       };
    //     });
    //   // console.log(userInfo.stakingChecked, 'userInfo')
    //   userInfo.invested = new Decimal(userInfo.invested)
    //     .div(new Decimal(Math.pow(10, 18)))
    //     .toString();
    //   userInfo.shares = new Decimal(userInfo.shares)
    //     .div(new Decimal(Math.pow(10, 18)))
    //     .toString();
    //   commit("getUserInfo", userInfo);
    // }
    function dfiNotEnough(baseState) {
        baseState.dfiNot = true;
        setBaseState(baseState);
    }
    function dfiNotEnoughClose(baseState) {
        baseState.dfiNot = false;
        setBaseState(baseState);
    }
    return {
        baseState,
        getDfi,
        getDfa,
        dfiNotEnough,
        dfiNotEnoughClose,
        getUserInfo,
        getStakeAmount,
        getNewUserInfo,
        getProgress,
        getCloseData,
    };
}
