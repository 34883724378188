import React, { memo, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import { routes } from "../router/index";
import AppHeader from "../components/appHeader/AppHeader";
const DFIAppMain = memo(() => {
    return (React.createElement(HashRouter, null,
        React.createElement(AppHeader, null),
        React.createElement(Suspense, { fallback: React.createElement("div", null, "loading") },
            React.createElement(Routes, null, routes.map((item) => {
                return (React.createElement(Route, { key: item.path, exact: item.true, path: item.path, element: item.element }));
            })))));
});
export default DFIAppMain;
