import React from "react";
// import Projects from "../pages/projects/Projects";
// import Staking from "../pages/staking/Staking";
// import Tier from "../pages/tier/Tier";
const Projects = React.lazy((_) => import("../pages/projects/Projects"));
const Staking = React.lazy((_) => import("../pages/staking/Staking"));
const Tier = React.lazy((_) => import("../pages/tier/Tier"));
export const routes = [
    {
        path: "/",
        exact: true,
        element: React.createElement(Projects, null),
    },
    {
        path: "/projects",
        element: React.createElement(Projects, null),
    },
    {
        path: "/staking",
        element: React.createElement(Staking, null),
    },
    {
        path: "/tier",
        element: React.createElement(Tier, null),
    },
];
